import PropTypes from "prop-types"
import React, { useState } from "react"
import { Helmet } from "react-helmet"
import ModalVideo from "react-modal-video"
import Layout from "../components/layout"
import Videoplaceholdertwo from "../images/3DSolutionspageProducttour.png"
import Videoplaceholder from "../images/3Dimmersiveadbanners01.png"
import Videoplaceholderthree from "../images/Interactivepresentation.png"
import Shapefour from "../images/bannerElement/white-design-element-four.svg"
import Shapeone from "../images/bannerElement/white-design-element-one.svg"
import Shapethree from "../images/bannerElement/white-design-element-three.svg"
import Shapetwo from "../images/bannerElement/white-design-element-two.svg"
import AnalyzingSTEP from "../images/icons/3DSolutions/Analyzing-STEP.svg"
import BoostEngagement from "../images/icons/3DSolutions/Boost-engagement.svg"
import Compositing from "../images/icons/3DSolutions/Compositing.svg"
import EnhanceVisualStorytelling from "../images/icons/3DSolutions/Enhance-visual-storytelling.svg"
import LightingRendering from "../images/icons/3DSolutions/Lighting-Rendering.svg"
import LowerCosts from "../images/icons/3DSolutions/Lower-costs.svg"
import ModelingTexturing from "../images/icons/3DSolutions/Modeling-Texturing.svg"
import RevolutionizeProduct from "../images/icons/3DSolutions/Revolutionize-product.svg"
import StoryboardingEnvironment from "../images/icons/3DSolutions/Storyboarding-Environment.svg"
import StreamlineSalesCycles from "../images/icons/3DSolutions/Streamline-sales-cycles.svg"
import image1 from "../images/image-one.svg"
import image3 from "../images/image-three.svg"
import image2 from "../images/image-two.svg"
import VideoBanner from "../video/3d-pagge-banner-video.mp4"
import LenovoVideo from "../video/Lenovo.mp4"

import "../styles/404.css"

const ThreeDSolutions = () => {
  const [isOpen, setOpen] = useState([false, false, false]) // Add more booleans if you have more cards
  const openModal = index => {
    let newIsOpen = [...isOpen]
    newIsOpen[index] = true
    setOpen(newIsOpen)
  }
  const closeModal = index => {
    let newIsOpen = [...isOpen]
    newIsOpen[index] = false
    setOpen(newIsOpen)
  }
  const cards = [
    {
      videoId: "924560508", // replace with your video id
      title: "3D immersive ad banners",
      text: "Capture attention & boost brand recall in the awareness stage.",
      img1: Videoplaceholder,
    },
    {
      videoId: "924560941", // replace with your video id
      title: "3D product tour videos",
      text: "Deepen product understanding while influencing purchase decisions.",
      img1: Videoplaceholdertwo,
    },
    {
      videoId: "924558055", // replace with your video id
      title: "Interactive presentations",
      text: "Simplify & engage with interactive presentations, driving final purchases.",
      img1: Videoplaceholderthree,
    },
    // Add more cards as needed
  ]
  return (
    <>
      <Helmet>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <title>3D Solutions | Position²</title>
        <script src="https://use.fortawesome.com/23bb216b.js"></script>
        <body className="home" />
      </Helmet>
      <Layout>
        <div class="threed-solutions content-creative">
          <section id="Banner">
            <div class="bgVideo">
              <video
                width="100%"
                playsInline
                muted
                autoPlay
                loop
                className="video-js BiggerScreen"
                data-setup="{'controls': false, 'autoplay': true, 'preload': true, 'loop':true }"
              >
                <source src={VideoBanner} type="video/mp4" />
              </video>
            </div>
            <div class="bannerContent">
              <div class="container">
                <div class="banner-holder">
                  <div class="images">
                    <div className="img-one image-shape">
                      <img src={Shapeone} alt="Image" />
                    </div>
                    <div className="img-two image-shape">
                      <img src={Shapetwo} alt="Image" />
                    </div>
                    <div className="img-three image-shape">
                      <img src={Shapethree} alt="Image" />
                    </div>
                    <div className="img-four image-shape">
                      <img src={Shapefour} alt="Image" />
                    </div>
                  </div>
                  <div class="title">
                    <div class="banner-title">
                      <h1>Bring your products to life in 3D</h1>
                      <p>
                        Leverage 3D technology to boost marketing and user engagement
                      </p>
                      <a className="button" href="/contact-us">
                        Get in touch
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section id="PageIntro">
            <div class="container">
              <h2>The power of hyper-realistic 3D renders</h2>
              <p>
                Captivate customers with interactive 3D models that allow them
                to explore your products like never before. Our immersive 3D
                solutions help you effortlessly integrate detailed, interactive
                3D product visualizations across your marketing.
              </p>
            </div>
          </section>
          <section id="TextImageSection">
            <div class="container">
              <div class="text-image-holder">
                <div class="text-wrap">
                  <h2>Engage customers in new ways</h2>
                  <p>
                    With 3D product visualizations, customers can:
                    <ul class="bullet-point">
                      <li>Zoom, rotate, and view products from all angles</li>
                      <li>Interact with realistic representations</li>
                      <li>Explore features as if holding the real product</li>
                    </ul>
                    <span>
                      This creates an immersive experience that educates and
                      excites customers beyond static images or video.
                    </span>
                  </p>
                  <a
                    href="https://www.position2studios.com/3d-services"
                    target="_blank"
                    class="view-3d"
                  >
                    View 3D portfolio
                  </a>
                </div>
                <div class="img-wrap">
                  <iframe
                    src="https://dev-upgradep2staging.pantheonsite.io/wp-content/themes/positiontwovone/p2-3d-animatiom/index6.html"
                    width="450px"
                    height="450px"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  ></iframe>
                </div>
              </div>
            </div>
          </section>
          <section id="Approach">
            <div class="container">
              <h2>Transform your marketing approach</h2>
              <p>Integrating 3D across your marketing stack will:</p>
              <div class="horizontalImageIconSec">
                <div class="moduleWrap">
                  <div class="eachModule">
                    <div class="icons">
                      <img
                        width="100"
                        height="100"
                        src={RevolutionizeProduct}
                      />
                    </div>
                    <div class="contents">
                      <p>Revolutionize Product Demonstrations</p>
                    </div>
                  </div>
                  <div class="eachModule">
                    <div class="icons">
                      <img
                        width="100"
                        height="100"
                        src={EnhanceVisualStorytelling}
                      />
                    </div>
                    <div class="contents">
                      <p>Enhance Visual Storytelling</p>
                    </div>
                  </div>
                  <div class="eachModule">
                    <div class="icons">
                      <img width="100" height="100" src={BoostEngagement} />
                    </div>
                    <div class="contents">
                      <p>Boost Engagement and Conversions</p>
                    </div>
                  </div>
                  <div class="eachModule">
                    <div class="icons">
                      <img
                        width="100"
                        height="100"
                        src={StreamlineSalesCycles}
                      />
                    </div>
                    <div class="contents">
                      <p>Streamline Sales Cycles</p>
                    </div>
                  </div>
                  <div class="eachModule">
                    <div class="icons">
                      <img width="100" height="100" src={LowerCosts} />
                    </div>
                    <div class="contents">
                      <p>Lower Costs Compared to Physical Prototypes</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section id="ImgVideoDesCard">
            <div class="container">
              <h2 class="title">What can we do for you</h2>
              <p>
                We create videos that enable you to achieve your targeted goals
                at every stage of the marketing campaign.
              </p>
              <ul class="cards">
                {cards.map((card, index) => (
                  <li className="cards__item" key={index}>
                    <div className="card">
                      <div className="card__image">
                        <img
                          alt="Video Placeholder"
                          width="398"
                          height="296"
                          src={card.img1}
                        />
                        <ModalVideo
                          channel="vimeo"
                          isOpen={isOpen[index]}
                          videoId={card.videoId}
                          onClose={() => closeModal(index)}
                        />
                        <button onClick={() => openModal(index)}>
                          <i className="ptwo ptwo-playicon"></i>
                        </button>
                        <div className="overlay"></div>
                      </div>
                      <div className="card__content">
                        <div className="card__title">{card.title}</div>
                        <p className="card__text">{card.text}</p>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </section>
          <div id="InnerSlider" className="home-testimonial">
            <div className="slider-wrapper">
              <div className="inner-slider">
                <div className="each-slider">
                  <div className="banner-cover">
                    <video
                      width="100%"
                      playsInline="playsinline"
                      muted="muted"
                      preload="yes"
                      autoPlay="autoplay"
                      loop="loop"
                      className="video-js BiggerScreen"
                      data-setup="{'controls': false, 'autoplay': true, 'preload': true, 'loop':true }"
                    >
                      <source src={LenovoVideo} type="video/mp4" />
                    </video>
                  </div>
                  <div className="slider-content">
                    <div className="container">
                      <div className="content-wrap">
                        {/* <p className="eyebrow">Recent Work</p> */}
                        <h2>Reimagining product showcase</h2>
                        <p>
                          Using advanced 3D modeling, we enriched Lenovo's
                          marketing efforts for ThinkBook Plus Twist by creating
                          a stunning product tour video, boosting customer
                          engagement and business growth.
                        </p>
                        <a
                          target="_blank"
                          href="https://www.position2studios.com/3d-services"
                        >
                          View samples
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <section id="Process">
            <div class="container">
              <h2>Our process</h2>
              <p>
                Our comprehensive 3D solution follows a meticulous, multi-stage
                process to bring your products to life in stunning,
                photorealistic detail. From analyzing technical specifications
                to delivering final rendered visuals and animations, we
                seamlessly guide your project through:
              </p>
              <div class="multiStageProcess">
                <div class="eachProcess">
                  <div class="iconImage">
                    <img width="100" height="100" src={AnalyzingSTEP} />
                    <span class="borderImg">
                      <img width="214" height="161" src={image1} />
                    </span>
                  </div>
                  <div class="processContent">
                    Analyzing STEP Files and CMF Specs
                  </div>
                </div>
                <div class="eachProcess">
                  <div class="iconImage">
                    <img width="100" height="100" src={ModelingTexturing} />
                    <span class="borderImg">
                      <img width="214" height="161" src={image2} />
                    </span>
                  </div>
                  <div class="processContent">Modeling and Texturing</div>
                </div>
                <div class="eachProcess">
                  <div class="iconImage">
                    <img
                      width="100"
                      height="100"
                      src={StoryboardingEnvironment}
                    />
                    <span class="borderImg">
                      <img width="214" height="161" src={image2} />
                    </span>
                  </div>
                  <div class="processContent">
                    Storyboarding, Environment, <br />
                    and Animation{" "}
                  </div>
                </div>
                <div class="eachProcess">
                  <div class="iconImage">
                    <img width="100" height="100" src={LightingRendering} />
                    <span class="borderImg">
                      <img width="214" height="161" src={image2} />
                    </span>
                  </div>
                  <div class="processContent">Lighting and Rendering</div>
                </div>
                <div class="eachProcess">
                  <div class="iconImage">
                    <img width="100" height="100" src={Compositing} />
                    <span class="borderImg">
                      <img width="160" height="161" src={image3} />
                    </span>
                  </div>
                  <div class="processContent">
                    Compositing, Static Renders and Animation
                  </div>
                </div>
              </div>
              <div class="bringideas">
                <h2>Bring ideas to life</h2>
                <p>
                  Our team takes your CAD files and product blueprints to
                  construct incredibly detailed 3D models down to the exact
                  specifications. We build digital replicas of products before
                  the first physical prototype is even produced!
                </p>
              </div>
            </div>
          </section>
          <section id="contactus-section">
            <div class="container">
              <h2>Let your customers experience the future today.</h2>
              <a className="button" href="/contact-us">
                Get in touch
              </a>
            </div>
          </section>
        </div>
      </Layout>
    </>
  )
}

ThreeDSolutions.propTypes = {
  siteTitle: PropTypes.string,
}

ThreeDSolutions.defaultProps = {
  siteTitle: ``,
}

export default ThreeDSolutions
